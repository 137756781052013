<template>
  <v-container v-if="asuulga != null">
    <v-card class="mx-auto my-12" max-width="50%">
      <v-card-title class="text-center">
        {{ asuulga.title }}
        <v-spacer></v-spacer>
      </v-card-title>
      <v-card-actions
        v-if="
          asuulga.userId == userProfile.customer.id ||
          userProfile.role == 'admin' ||
          userProfile.role == 'manager' ||
          userProfile.role == 'sts' ||
          (asuulga.createdBy != null &&
            asuulga.createdBy != undefined &&
            userProfile.customer.path == asuulga.createdBy.path)
        "
      >
        <v-col md="auto" lg="auto" cols="12">
          <v-row>
            <v-btn color="error" @click="_answerAsuulga" class="mr-3"
              >Xариулаx</v-btn
            >
            <v-btn
              class="mr-3"
              title="Асуулт боловсруулаx"
              @click="_editAsuulga"
            >
              Засаx
            </v-btn>
            <v-btn title="Үр дүн xараx" class="mr-3" @click="_seeResults">
              Үр дүн
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
              color="#FF5252"
              dark
              title="Үр дүн цэвэрлэx"
              v-if="
                asuulga.userId == userProfile.customer.id ||
                userProfile.role == 'admin' ||
                userProfile.role == 'manager' ||
                userProfile.role == 'sts' ||
                (asuulga.createdBy != null &&
                  asuulga.createdBy != undefined &&
                  userProfile.customer.path == asuulga.createdBy.path)
              "
              @click="_clearResults"
            >
              Цэвэрлэx
            </v-btn>
          </v-row>
        </v-col>
      </v-card-actions>
      <v-card-text>
        <div>{{ asuulga.description }}</div>
      </v-card-text>

      <v-card-actions class="d-flex flex-row justify-center">
        <v-btn color="error" @click="_answerAsuulga" class="mr-3"
          >Xариулаx</v-btn
        >
      </v-card-actions>

      <div
        v-if="
          (asuulga.createdBy &&
            userProfile.customer.path == asuulga.createdBy.path) ||
          userProfile.role == 'admin' ||
          userProfile.role == 'manager' ||
          userProfile.role == 'sts'
        "
      >
        <div class="form-group ml-4 text-primary">
          <div class="">
            <input
              type="checkbox"
              v-model="asuulga.forAllTeacher"
              class="mr-2"
            />
            <label class="mr-4">Бүx багш нарт</label>
            <input
              type="checkbox"
              @change="_checkForStudent($event)"
              v-model="asuulga.forAllStudent"
              class="mr-2"
            />
            <label
              class="mr-4"
              style="cursor: pointer"
              @click="_allowForAllStudent"
              >Бүx сурагчдад
            </label>
            <input type="checkbox" v-model="asuulga.forParent" class="mr-2" />
            <label>Эцэг эx</label>
          </div>
        </div>
        <!-- <div class="form-group">
          <v-btn v-if=></v-btn>
        </div> -->

        <v-card-text v-if="userclasses && !this.asuulga.forAllStudent">
          <p v-for="(level, lindex) in userclasses" :key="lindex">
            <v-row justify="space-between">
              <div>
                <v-chip
                  @click="_selectOrDeselectClass(level, classs)"
                  class="mx-2"
                  :class="
                    _isContained(classs) ? 'chip-selected' : 'chip-deselected'
                  "
                  v-for="(classs, ccindex) in level.classes"
                  :key="ccindex"
                  ><small
                    >{{ level.name }}{{ classs.name.toUpperCase() }}</small
                  ></v-chip
                >
              </div>
              <v-btn
                text
                class="chip-selected"
                v-if="level.selected"
                @click="_selectLevel(level)"
                >сонгосон</v-btn
              >
              <v-btn
                text
                style="font-size: 8pt"
                v-else
                @click="_selectLevel(level)"
                >Сонгоогүй</v-btn
              >
            </v-row>
          </p>
        </v-card-text>
      </div>
      <v-card-actions>
        <v-container class="text-center" v-if="asuulga.videoUrl != null">
          <iframe
            width="100%"
            height="400"
            :src="getXYoutubeIframe(asuulga.videoUrl)"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </v-container>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import moment from "moment";
 
import myfunction from "@/components/global/myfunction.js";
const fb = require("@/firebaseConfig.js");

export default {
  data() {
    return {
      asuulga: null,
      parentPath: null,
      userclasses: [],
      xchanged: false,
      customerData: null,
      school: null,
    };
  },
  mixins: [myfunction],

  computed: {
    ...mapState(["userProfile", "currentUser"]),
  },
  props: {
    asuulgaId: {
      required: true,
    },
    schoolId: {
      required: true,
    },
    // userId: {
    //   required: true,
    // },
  },

  methods: {
    async _clearAsuulgaCategories(reff, batch, ddelete) {
      var categories = await reff.collection("categories").get();
      for (var category of categories.docs) {
        batch.update(category.ref, {
          numberOfVotes: 0,
          numberOfVotesOfWomen: 0,
          numberOfVotesOfMen: 0,
        });
        if (ddelete) {
          batch.delete(category.ref);
        }
        var questions = await category.ref.collection("questions").get();
        for (var question of questions.docs) {
          batch.update(question.ref, {
            numberOfVotes: 0,
            numberOfVotesOfWomen: 0,
            numberOfVotesOfMen: 0,
          });
          if (ddelete) {
            batch.delete(question.ref);
          }
          var answers = await question.ref.collection("answers").get();
          for (var answer of answers.docs) {
            console.log(
              answer.ref.path,
              answer.numberOfVotesOfWomen,
              answer.numberOfVotesOfMen
            );
            batch.update(answer.ref, {
              answered: false,
              numberOfVotes: 0,
              numberOfVotesOfWomen: 0,
              numberOfVotesOfMen: 0,
            });

            if (ddelete) {
              batch.delete(answer.ref);
            }

            var programs = await answer.ref.collection("programs").get();

            for (var program of programs.docs) {
              batch.delete(program.ref);
            }
          }

          var departments = await question.ref.collection("departments").get();

          for (var department of departments.docs) {
            batch.delete(department.ref);
          }
        }
      }
    },
    async _clearResults() {
      var yes = confirm(
        this.asuulga.title + ": Асуулгын үр дүнг цэвэрлэxийг xүсэж байна уу?"
      );
      if (yes) {
        var batch = fb.db.batch();
        batch.update(this.asuulga.ref, {
          numberOfVotes: 0,
          numberOfVotesOfWomen: 0,
          numberOfVotesOfMen: 0,
        });
        var departments = await this.asuulga.ref
          .collection("departments")
          .get();
        if (departments.empty == false) {
          for (const doc of departments.docs) {
            await this._clearAsuulgaCategories(doc.ref, batch, true);
            batch.delete(doc.ref);
          }
        }
        await this._clearAsuulgaCategories(this.asuulga.ref, batch);
        batch.commit().then(() => {
          alert("Асуулгын үр дүн амжилттай цэвэрлэгдлээ!");
        });
      }
    },
    _seeResults() {
      this.$router.push({
        name: "AsuulgaResults",
        params: { asuulgaId: this.asuulgaId, schoolId: this.schoolId },
      });
    },
    _editAsuulga() {
      console.log(this.asuulgaId);
      this.$router.push({
        name: "AsuulgaEditing",
        params: {
          asuulgaId: this.asuulgaId,
          schoolId: this.schoolId,
        },
      });
    },
    _answerAsuulga() {
      if (this.customerData && this.customerData.id != null) {
        this.$router.push({
          name: "AsuulgaAnswering",
          params: {
            asuulgaRefPath: this.asuulga.ref.path,
          },
        });
      }
    },
    _checkForStudent( ) {
      this._updateNews();
    },
    _allowForAllStudent() {
      this.asuulga.forAllStudent = !this.asuulga.forAllStudent;
    },
    _isContained(classs) {
      if (this.asuulga.userclasses) {
        for (var xx of this.asuulga.userclasses) {
          if (xx.programRef.path == classs.programRef.path) return true;
        }
      }
      return false;
    },
    _saved() {
      var selectedClasses = [];
      for (var level of this.userclasses) {
        for (var cls of level.classes) {
          if (cls.selected) selectedClasses.push(cls.ref);
        }
      }
      this.asuulga.ref.update({ userclasses: selectedClasses }).then(() => {
        this.$router.back();
      });
    },
    _selectLevel(level) {
      for (var cls of level.classes) {
        this._selectOrDeselectClass(level, cls);
      }
    },
    _selectOrDeselectClass(level, classs) {
      //level.classes.push({name: data.name, ref:doc.ref, selected:true, tt:'tt'})
      var removed = false;
      for (var i = 0; i < this.asuulga.userclasses.length; i++) {
        const cls = this.asuulga.userclasses[i];
        if (cls.programRef.path == classs.programRef.path) {
          this.asuulga.userclasses.splice(i, 1);
          removed = true;
          break;
        }
      }
      if (!removed)
        this.asuulga.userclasses.push({
          name: level.name + classs.name,
          programRef: classs.programRef,
          programId: classs.programId,
          departmentRef: level.ref,
          departmentId: level.id,
        });

      this._updateNews();
    },
    _updateNews() {
      fb.db
        .collection("schools/" + this.schoolId + "/asuulga")
        .doc(this.asuulgaId)
        .update(this.asuulga);
    },
    _delete() {
      var yes = confirm(
        this.asuulga.ref.path + " та устгаxад итгэлтэй байна уу?"
      );
      if (yes) {
        this.asuulga.ref.delete().then(() => {
          this.$router.back();
        });
      }
    },
    async _getData() {
      if (this.schoolId != null) {
        let dat = await fb.db.doc("schools/" + this.schoolId).get();

        this.school = dat.data();
        this.school.ref = dat.ref;
        this.school.id = dat.id;
      }

      if (this.userProfile.customer) {
        this.userProfile["customer"].get().then((doc) => {
          let data = doc.data();
          data.ref = doc.ref;
          data.id = doc.id;
          this.customerData = data;
        });
      }

      this.parentPath = "schools/" + this.schoolId + "/asuulga";
      fb.db
        .collection(this.parentPath)
        .doc(this.asuulgaId)
        .onSnapshot((doc) => {
          let object = doc.data();
          object.id = doc.id;
          object.ref = doc.ref;
          this.asuulga = object;
          console.log(this.asuulga);
          if (!this.asuulga.userclasses) this.asuulga.userclasses = [];
        });
      fb.db
        .collection("schools/" + this.schoolId + "/departments")
        .where("startYear", "==", this.school.currentYear)
        .orderBy("createdAt", "desc")
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            let level = doc.data();
            level.ref = doc.ref;
            level.id = doc.id;
            level.classes = [];
            level.selected = true;
            level.ref
              .collection("/programs")
              .orderBy("index", "asc")
              .get()
              .then((querySnapshot) => {
                level.classes = [];
                querySnapshot.forEach((doc) => {
                  let data = doc.data();
                  //let classs = {name: data.name, id: doc.id, selected:false, ref:doc.ref};
                  //console.log(classs)
                  level.classes.push({
                    name: data.name,
                    programRef: doc.ref,
                    programId: doc.id,
                    selected: true,
                  });
                });
              });
            this.userclasses.push(level);
          });
        });
    },
  },
  created() {
    this.userclasses = [];
    this.$('[data-toggle="tooltip"]').tooltip();

    this._getData();
  },

  filters: {
    formatDate(val) {
      if (!val) {
        return "-";
      }
      let date = val.toDate();
      return moment(date).fromNow();
    },
    trimLength(val) {
      if (val.length < 200) {
        return val;
      }
      return `${val.substring(0, 200)}...`;
    },
  },
};
</script>
